import React, { useState, useContext } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { useTranslation } from 'react-i18next'
import './styles.css'

import Logo from '../../Components/Logo.js'
import LangSwitcher from '../../Components/LangSwitcher.js'
import Link from '../../Components/Link.js'
import RollingProducts from '../../Components/RollingProducts/RollingProducts'
import { TwoFaVerification } from '../../Components/TwoFaVerification';

import LoginForm from './Forms/LoginForm/LoginForm'
import SamlForm from './Forms/SamlForm/SamlForm'
import PasswordRecovery from './Forms/PasswordRecovery/PasswordRecovery.js'
import ChangePassword from './Forms/ChangePassword/ChangePassword.js'
import ResetPassword from './Forms/ResetPassword/ResetPassword.js'
import WrongIp from './Forms/WrongIp.js'


import TDS from '../../assets/TDS.png'
import BP from '../../assets/BP.png'
import AI from '../../assets/AI.png'
import SB from '../../assets/SB.png'

import AppContext from '../../Components/Context/AppContext'

function Authorization(props) {

    const { t } = useTranslation('authentication');
    const ct = useTranslation('common').t;

    const { sessionParams } = useContext(AppContext);
    const route = useRouteMatch();
    const productList = [
        {
            i: 1,
            name: 'Threat Intelligence',
            description: t('products.description.attributionIntelligence'),
            link: t('products.demoLinks.ai'),
            img: AI,
        },
        {
            i: 2,
            name: 'Managed XDR',
            description: t('products.description.TDS'),
            link: t('products.demoLinks.tds'),
            img: TDS,
        },
        {
            i: 3,
            name: t('products.name.SBSP'),
            description: t('products.description.SBSP'),
            link: t('products.demoLinks.sb'),
            img: SB,
        },
        {
            i: 4,
            name: 'Digital Risk Protection',
            description: t('products.description.brandProtection'),
            link: t('products.demoLinks.bp'),
            img: BP,
        },
        // {
        //   i: 5,
        //     name: 'Attack Surface Management',
        //     description: t('products.description.riskTracer'),
        //     link: t('products.demoLinks.rt'),
        //     img: RT,
        // },
    ];

    const [currentProductExtend, setProductExtend] = useState(productList[0]);

    let ShowedComponent = <LoginForm />;
    if (route.url.search(/^\/2fa\/verification/) !== -1) {
        ShowedComponent = <TwoFaVerification />
    } else if (route.url.search(/^\/reset_password/) !== -1) {

        // Редиректим в корень, если нет токена или если пользователь авторизован
        if (!route.params.hasOwnProperty('token') || sessionParams.isAuthenticated) {

            window.location.href = '/';
            return;

        }

        ShowedComponent = <ResetPassword />;

    } else if (route.url.search(/^\/recovery_password/) !== -1) {
        ShowedComponent = <PasswordRecovery />
    } else if (route.url.search(/^\/saml_auth/) !== -1) {
        ShowedComponent = <SamlForm />
    } else if (route.url.search(/^\/change_password/) !== -1) {

        // Редиректим в корень, если юзер не авторизован
        if (!sessionParams.isAuthenticated) {

            window.location.href = '/';
            return;

        }

        ShowedComponent = <ChangePassword />

    } else if (route.url.search(/^\/forbidden/) !== -1) {
        ShowedComponent = <WrongIp ip={sessionParams.userIp} project={route.params.project} />
    }

    return (
        <div className="Authorization">
            <div className="Authorization--StartSide">
                <Link href={ct('gibLink')} target="_blank" ><Logo className="Authorization--Logo" /></Link>
                <span className="Authorization--StartSide__title">
                    {t('products.title')}
                </span>
                <span className="Authorization--StartSide__description">
                    {t('products.description.main')}
                </span>
                <div className="Authorization--StartSide__rolling-wrapper">
                    <RollingProducts
                        productList={productList}
                        duration={7000}
                        onChange={(product) => setProductExtend(product)}
                    />
                    <TransitionGroup
                        className="Authorization--product-img-block"
                    >
                        {currentProductExtend.img && (
                            <CSSTransition
                                key={currentProductExtend.i}
                                timeout={2000}
                                classNames="product-img"
                            >
                                <img
                                    className="Authorization--product-img"
                                    src={currentProductExtend.img}
                                    alt={currentProductExtend.name}
                                />
                            </CSSTransition>
                        )}
                    </TransitionGroup>
                </div>
            </div>
            <div className="Authorization--EndSide">
                <div className="Authorization--Header">
                    <LangSwitcher checked={props.lang} onChange={props.onChangeLang} />
                </div>
                <div className="Authorization--LoginForm">
                    {ShowedComponent}
                </div>
                <div className="Authorization--Footer">
                    <div className="Authorization--Footer__support">
                        {ct('phone')} ·
                        <Link href={"mailto:" + ct('email')}>
                            {' ' + ct('email')}
                        </Link>
                    </div>
                    <div>
                        {`© 2003 – ${new Date(Date.now()).getFullYear()} `}
                        <Link href={ct('gibLink')} >{t('footerName')}</Link>
                        {t('footerDescription')}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Authorization
