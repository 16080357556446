import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { api } from '../Components/Api';
import { getErrorMsg } from './useSaveTwoFa';

export const useVerification = () => {
    const t = useTranslation('twoFa').t;
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState();
    const [data, setData] = useState();

    const verification = async (code, rememberMe = false) => {
        try {
            const response = await api.post('/2fa/verification_process', {
                code,
                rememberMe
            })
            if (response.error) {
                if (response.error === 'too_many_attempts') {
                    window.location.href = '/logout';
                }
                setSuccess(false);
                setError({
                    error: response.error,
                    message: getErrorMsg(response.error, t)
                })
            } else {
                setSuccess(true);
                setError(undefined);
                setData(response);

                if (window.gib && window.gib.setIdentity) {
                    window.gib.setIdentity(response.user);
                }
            }
        } catch (ex) {
            setSuccess(false);
            setError({
                error: ex,
                message: ex.statusText
            })
        }
    }
    return {
        data,
        error,
        success,
        verification,
        setError
    }
}
